var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    { ref: "obs", attrs: { tag: "form" } },
    [
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "my-2 d-flex flex-column align-center" },
          [
            _vm.polling
              ? _c(
                  "div",
                  { staticClass: "d-flex flex-column align-center" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "my-2 text-center",
                        attrs: {
                          "align-content": "center",
                          align: "center",
                          justify: "center",
                        },
                      },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.status) },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.showCanvas
                      ? _c("canvas", {
                          ref: "container",
                          attrs: { id: "qr-code-container" },
                        })
                      : _c("v-progress-circular", {
                          staticClass: "mr-2",
                          attrs: {
                            size: 20,
                            color: "primary",
                            indeterminate: "",
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-2",
                        attrs: { color: "error", disabled: _vm.canceled },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.prepareCancel.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Avbryt")]
                    ),
                  ],
                  1
                )
              : _vm.loading && !_vm.polling
              ? _c("v-progress-circular", {
                  staticClass: "mr-2",
                  attrs: { size: 20, color: "primary", indeterminate: "" },
                })
              : _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column align-center w-100",
                    attrs: { id: "login-options" },
                  },
                  [
                    _c("validation-provider", {
                      attrs: {
                        name: _vm.$t("auth.personalNumberLabel"),
                        rules: "required|digits:12",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                solo: "",
                                clearable: "",
                                label: _vm.$t("auth.personalNumberInputLabel"),
                                type: "tel",
                                "error-messages": errors,
                                disabled: _vm.loading,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.personalNumber,
                                callback: function ($$v) {
                                  _vm.personalNumber = $$v
                                },
                                expression: "personalNumber",
                              },
                            })
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-2 text-none",
                        attrs: {
                          color: "primary",
                          type: "submit",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.startTransaction(false)
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tMobilt BankID QR-kod\n\t\t\t\t\t")]
                    ),
                    _vm._v(" "),
                    _vm.isMobile
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "my-2 text-none",
                            attrs: {
                              color: "primary",
                              loading: _vm.loading,
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.startTransaction(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\tMobilt BankID på denna enhet\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isMobile
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "my-2 text-none",
                            attrs: {
                              color: "primary",
                              loading: _vm.loading,
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.startTransaction(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        BankID på dator\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }