var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { align: "center", justify: "center" } },
    [
      _c("v-col", { attrs: { cols: "12", sm: "12", md: "6" } }, [
        _c(
          "form",
          { attrs: { action: "" } },
          [
            _vm.logo
              ? _c("img", {
                  staticClass: "mb-3 d-block",
                  staticStyle: { "max-width": "200px", margin: "auto" },
                  attrs: { src: "/domain/" + _vm.logo, alt: _vm.companyName },
                  on: {
                    "~error": function ($event) {
                      return _vm.logoFallback.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-card",
              [
                _c("v-card-title", [
                  _vm._v(_vm._s(_vm.$t("auth.selectEntityTitle"))),
                ]),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.entities,
                        "item-text": "descriptive",
                        "item-value": "id",
                      },
                      model: {
                        value: _vm.selectedEntity,
                        callback: function ($$v) {
                          _vm.selectedEntity = $$v
                        },
                        expression: "selectedEntity",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          type: "submit",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("auth.selectEntityButtonText")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }